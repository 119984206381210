$MuiButtonPrefix: MuiButton-;

.#{$MuiButtonPrefix}root {
    &.#{$MuiButtonPrefix}containedPrimary {
        .#{$MuiButtonPrefix}label {
            color: inherit;
        }
    }
}


//hide scroll bar

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
}
// ::-webkit-scrollbar-thumb {
//     border-radius: 10px;
//     background-color: rgba(0,0,0,0);
//     -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
// }

/* Track */

::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #eaeaea;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #464646;
  }

  .scrollbars-visible-always {
    .tree-view-scroller {
        &::-webkit-scrollbar {
            display: none;
            width: 0.25rem;
            height: 0.25rem;
        }
        &:hover::-webkit-scrollbar {
            display: block;
        }
    }
}